import React from "react";
import x from "./x.png"; // Ensure the path to x.png is correct
import { FaFacebook, FaInstagram, FaYoutube, FaTiktok } from "react-icons/fa";

function SocialMedia() {
  return (
    <div className="flex justify-center space-x-4 mt-10">
      <a
        href="https://twitter.com/aireazulventure"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={x}
          alt="x"
          className="hover:text-blue-900"
          style={{ width: 50, height: 50 }}
        />{" "}
        {/* Added src and style */}
      </a>
      <a
        href="https://facebook.com/AireAzulVentures"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaFacebook size={50} className="text-blue-800 hover:text-blue-900" />
      </a>
      <a
        href="https://www.instagram.com/aireazulventures/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaInstagram size={50} className="text-pink-500 hover:text-pink-900" />
      </a>
      <a
        href="https://youtube.com/@Aireazulventures"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaYoutube size={50} className="text-red-500 hover:text-red-900" />
      </a>
      <a
        href="https://tiktok.com/@aireazulventures"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaTiktok size={50} className="text-black hover:text-gray-900" />
      </a>
    </div>
  );
}

export default SocialMedia;
