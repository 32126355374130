import React from "react";
import AboutIntro from "./Eldon-About.jpg";

function Intro() {
  return (
    <div className="grid grid-cols-2 items-center sm:grid-cols-1 px-10 md:grid-cols-1">
      <div className="h-auto pt-20 px-10 overflow-hidden">
        <img
          src={AboutIntro}
          alt="Eldon"
          className="w-full h-auto object-cover rounded-xl "
        />
      </div>

      <div>
        <h1 className="sm:text-3xl md:text-6xl lg:text-6xl text-6xl font-semibold md:px-7 text-primary pt-10">
          Start your journey
          <br />
          <span className="text-secondary font-semibold">today.</span>
        </h1>
      </div>
    </div>
  );
}

export default Intro;
