import React from "react";
import lifeCoachImage from "./LifeCoach.jpg";

function WhyALifeCoach() {
  return (
    <div>
      <h1 className="text-center text-7xl font-semibold text-primary sm:text-4xl">
        Why A Choose <br />
        <b className="text-secondary font-semibold">Life Coach</b>?
      </h1>
      <div className="h-[500px] sm:h-[300px] flex justify-center items-center p-5">
        <img
          src={lifeCoachImage}
          alt="Life Coach"
          className="h-full object-contain sm:object-cover rounded-md"
        />
      </div>

      <p className="text-gray-600 text-md mt-10 mx-10">
        Choosing a life coach is an investment in yourself and your future.
        Unlike friends or family, a life coach provides an objective
        perspective, free from bias or judgment. They are trained to listen
        actively, ask powerful questions, and challenge you to think differently
        about your circumstances. With their support, you gain clarity and
        direction, enabling you to make informed decisions that align with your
        true desires. Life coaching is not just about achieving goals; it's
        about fostering a deeper understanding of yourself and empowering you to
        take charge of your life. Embrace the opportunity to transform your
        challenges into stepping stones for success with the guidance of a
        dedicated life coach by your side.
      </p>
      <p className="text-gray-600 text-md mt-10 mx-10">
        What you want to achieve? Do you trust yourself? When was the last time
        you saw a break-through?
      </p>

      <h1 className="text-primary text-4xl font-semibold mt-10 mx-10">
        The answers to all your questions will come.
        <b className="text-secondary font-semibold sm:text-4xl md:text-6xl">
          <br />
          Believe it! It's in you.
        </b>
      </h1>

      <p className="text-gray-600 text-md mt-10 mx-10">
        How you think about yourself determines if you will be successful or
        not. Life coaching is a tool used for you to create and manifest,
        achieve, advance, grow, and more. Without it, you are likely to stay
        stuck in a rut.
      </p>
    </div>
  );
}

export default WhyALifeCoach;
