import React from "react";

function WhatWeDo() {
  const items = [
    {
      title: "Psychotherapist",
      description:
        "A psychotherapist is a licensed mental health professional who helps individuals overcome emotional and psychological issues. They use various therapeutic techniques to treat mental illnesses, such as depression, anxiety, and trauma. As a life coach, I do not diagnose or treat mental disorders. Instead, I focus on helping clients (you) achieve their goals and improve their overall well-being through goal-setting, accountability, and personal development.",
    },
    {
      title: "Psychologist",
      description:
        "Psychologists are licensed mental health professionals who study human behavior, emotions, and cognition. They often conduct research, administer psychological tests, and provide counseling services. While psychologists may help clients with personal growth and development, their primary focus is on understanding and treating mental health conditions. As a life coach, I do not have the same level of clinical training or expertise in diagnosing and treating mental illnesses.",
    },

    {
      title: "Psychiatrist",
      description:
        "Psychiatrists are medical doctors who specialize in the diagnosis and treatment of mental health disorders. They can prescribe medication and provide psychotherapy. As a life coach, I do not have the medical training or authority to diagnose or treat mental illnesses. My role is to support clients (you) in achieving their goals and improving their overall well-being, not to provide clinical mental health services.",
    },
  ];
  return (
    <div className="sm:mt-10 lg:mt-3">
      <h1 className="text-secondary text-7xl text-center px-20 font-semibold sm:text-5xl md:text-6xl sm: -mt-10">
        What I am not
      </h1>

      <div className="h-96 w-full bg-primary mt-12 sm:hidden md:hidden md:mb-4"></div>
      <div className="grid grid-cols-3 sm:grid-cols-1 gap-10 mx-32 sm:mx-0 -mt-80 sm:w-full sm:px-5 md:grid-cols-1 xl:grid-cols-1 lg:grid-cols-1  md:m-5 md:mr-5 md:w-[95%]">
        {items.map((item) => (
          <div className="p-5 bg-white border shadow flex flex-col space-y-5 items-center transform hover:scale-105 duration-300 sm:selection:w-full sm:mr-4 md:mr-7">
            <h1 className="text-2xl">{item.title}</h1>
            <p className="text-gray-600 text-md">{item.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default WhatWeDo;
