import React from "react";
import Layout from "../../components/Layout";
import Intro from "./Intro";
import WhoIAm from "./WhoIAm";
import WhatIAmNot from "./WhatIAmNot";
import SocialMedia from "../../components/SocialMedia";

function About() {
  return (
    <Layout>
      <div className="grid grid-cols-1 px-3 gap-20 sm:p-5 md:p-5 p-0">
        <Intro />
        <WhoIAm />
        <WhatIAmNot />
        <SocialMedia />
      </div>
    </Layout>
  );
}

export default About;
