import React from "react";

function MarketingStrategies() {
  const strategies = [
    {
      title: "Goal Setting and Accountability",
      description:
        "My task as life coach is to help you identify and articulate your goals, breaking them down into manageable steps. With regular check-ins and support, I will ensure you stay motivated and accountable throughout your journey.",
    },
    {
      title: "Personal Development",
      description:
        "As we work together, I'll provide tools and techniques for self-discovery, helping you build self-awareness, confidence, and resilience. It's designed to enhance your emotional intelligence and improve your overall well-being.",
    },
    {
      title: "Career Coaching",
      description:
        "If you're feeling unfulfilled in your career, I can assist you in exploring new opportunities, refining your professional skills, and creating a strategic plan to advance your career or transition to a new field.",
    },
    {
      title: "Stress Management",
      description:
        "As a Life coach, I offer strategies to manage stress and cultivate a healthy work-life balance. I'll guide you in prioritizing your well-being, helping you create a lifestyle that nurtures both personal and professional success.",
    },
  ];

  return (
    <div>
      <h1 className="text-5xl font-semibold text-primary sm:text-3xl sm:mt-10 px-10">
        Have you lost your path?
      </h1>
      <h1 className="text-7xl font-semibold text-primary mt-10 sm:text-5xl px-10">
        Your <b className="text-secondary font-semibold">COACH</b> <br />
        is here...
      </h1>
      <p className="text-gray-600 text-xl mt-10 px-10 pb-10">
        As your life coach will be a supportive partner who helps you navigate
        the complexities of life, providing guidance, encouragement, and
        accountability as you work towards your goals. By utilizing proven
        techniques and personalized strategies, a can empower you to overcome
        obstacles, discover your true potential, and create a fulfilling life
        that aligns with your values and aspirations. Whether you're seeking
        clarity in your career, relationships, or personal growth, I am
        dedicated to helping you unlock the doors to a brighter future.
      </p>

      <div className="grid grid-cols-2 gap-20 mt-10 sm:grid-cols-1 md:grid-cols-1 px-10">
        {strategies.map((item) => {
          return (
            <div className="border border-primary p-0 flex-col space-y-10 rounded transform hover:scale-105 duration-300 sm:hover:scale-95">
              <h1 className="mx-20 sm:mx-5 text-center -mt-8 bg-white border-primary border text-2xl lg:mx-5 text-secondary rounded font-semibold p-2">
                {item.title}
              </h1>
              <p className="text-gray-600 text-xl hover:text-primary px-5 pb-5">
                {item.description}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default MarketingStrategies;
