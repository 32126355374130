import React from "react";
import EldonHome from "./Eldon-Home.jpg";

function Intro() {
  return (
    <div className="grid grid-cols-2 items-center sm:grid-cols-1 px-10 md:grid-cols-1">
      <div className="h-auto pt-20 px-10 overflow-hidden">
        <img
          src={EldonHome}
          alt="Eldon"
          className="w-full h-auto object-cover rounded-xl "
        />
      </div>

      <div>
        <h1 className="text-7xl font-semibold text-primary sm:text-6xls mt-14 lg:text-5xl">
          Aire Azul Ventures <br />
          <b className="text-secondary font-semibold">Life Coaching</b>
        </h1>
      </div>
    </div>
  );
}

export default Intro;
