import React from "react";
import Layout from "../../components/Layout";
import Intro from "./Intro";
import ContactForm from "./ContactForm";
import SocialMedia from "../../components/SocialMedia";

function Contact() {
  return (
    <Layout>
      <div className="grid grid-cols-1 px-3 gap-20 sm:p-5 md:p-5 p-0">
        <Intro />
        <ContactForm />
        <SocialMedia />
      </div>
    </Layout>
  );
}

export default Contact;
