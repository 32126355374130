import React from "react";
import Layout from "../../components/Layout";
import Intro from "./Intro";
import TestimonialList from "./TestimonialList";
import SocialMedia from "../../components/SocialMedia";

function Testimonials() {
  return (
    <Layout>
      <Intro />
      <TestimonialList />
      <SocialMedia />
    </Layout>
  );
}

export default Testimonials;
