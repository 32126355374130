import React from "react";

function WhatWeDo() {
  const items = [
    {
      title: "Latrice C.",
      description:
        "Thank you so much for this Eldon! You have changed my life in such a positive way. I appreciate you; your time and patience for listening to and motivating me. Thank you, Eldon, for changing my life! I was caught up in a cycle of stinking thinking day in and day out.  A close friend recommended that I speak with you, and my life has changed from that day forward.  With your consultation and guidance, I wake up daily with a positive outlook on life, Thanks to You. With your teachings I was able to manifest a new job with the working conditions that I want! I no longer speak negatively, and I view life so much differently. Now in the morning, when I wake up, I put on my make up and say a little prayer!  Anyone out there that is experiencing challenges in life, I recommend consulting with Eldon.  He has shown me that all it takes is one small pivot and you can go from a negative life to a positive one, living your dreams out loud!",
    },
    {
      title: "S. Kathleen S.",
      description:
        "Eldon has been my lifesaver when it comes to helping me with work goals and challenges. He has been my go-to guy for over 10 years and always gives me the time and attention I need to get back on track. He never makes me feel that my questions are too simple or trivial. No matter how frustrated I become, Eldon’s steady demeanor and calm approach to the problem brings comfort. He never rushes through a problem or lets my stress affect him. This is Eldon’s superpower – he is so capable of identifying the source of the problem and then finding the solution. I feel that everyone needs an Eldon, but I’m so glad he’s the one I have! ",
    },
    {
      title: "Miguel A.",
      description:
        "Working with Eldon has been transformative. His guidance has been instrumental in my journey toward discovering my best self. Through his insights, I've come to understand and embrace my inherent worth, recognizing myself as deserving of respect and success. Eldon has helped me achieve a significant breakthrough by empowering me to demand the respect I’ve earned, leading to a renewed sense of self-worth and confidence. His support has been crucial in unlocking my potential and creating meaningful change in my life.",
    },

    {
      title: "Jeffrey K.",
      description:
        "Thanks to Eldon's guidance, I've found renewed motivation in sharing the power of affirmations, and I'm inspired to help others discover their inner strengths. Through Eldon's encouragement, I've realized my value and gifts, empowering me to stay positive and grateful, no matter what life brings. Eldon's gift as a magician and his deep commitment as a friend and mentor have transformed my outlook, helping me leave doubt behind and embrace each day with thankfulness and renewed purpose",
    },
  ];
  return (
    <div className="z-20 sm:mt-10 lg:mt-3">
      <div className="h-96 w-full bg-primary mt-12 sm:hidden md:hidden md:mb-4"></div>
      <div className="grid grid-cols-4 sm:grid-cols-1 gap-10 mx-32 sm:mx-0 -mt-64 sm:w-full sm:px-5 lg:grid-cols-1 md:grid-cols-1  xl:grid-cols-1 md:m-5 md:mr-5 md:w-[95%]">
        {items.map((item) => (
          <div className="p-5 bg-white border shadow flex flex-col space-y-5 items-center transform hover:scale-105 duration-300 sm:selection:w-full sm:mr-4 md:mr-7">
            <h1 className="text-2xl">{item.title}</h1>
            <p className="text-gray-600 text-md">{item.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default WhatWeDo;
