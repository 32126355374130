import React from "react";

function WhatWeDo() {
  const items = [
    {
      title: "Life Coach",
      icon: "images/web.png",
      description:
        "As a dedicated life coach, my mission is to empower individuals to discover their true potential and navigate their personal journeys with confidence. I believe that everyone has the ability to create a fulfilling life, and I am here to guide you through the process of self-discovery and growth. With a compassionate approach, I provide the tools and support necessary to help you set and achieve meaningful goals, overcome obstacles, and cultivate a positive mindset. Together, we will unlock your strengths and transform your aspirations into reality.",
    },
    {
      title: "Fitness Instructor",
      description:
        "With a deep-rooted passion for health and wellness that runs in my family, I am a certified fitness instructor committed to helping others lead active and healthy lives. Growing up in an environment that prioritizes well-being, I understand that physical health is a crucial component of overall happiness and success. Through personalized fitness programs and motivational coaching, I inspire my clients to embrace movement, build strength, and improve their physical health.",
    },
    {
      title: "Entrepreneur",
      description:
        "As an entrepreneur, I thrive on innovation and creativity, constantly seeking new ways to inspire and uplift others. My journey in business has taught me the importance of resilience, adaptability, and strategic thinking. I leverage my experiences to help aspiring entrepreneurs and individuals alike develop their visions and turn their ideas into reality. Whether you’re looking to start a new venture or enhance your current projects, I provide the guidance and support necessary to navigate the challenges of entrepreneurship and achieve sustainable success.",
    },

    {
      title: "Spiritual Magician",
      description:
        "Embracing the title of 'spiritual magician', I integrate elements of spirituality into my coaching practice. I believe in the power of the universe and the magic that lies within each of us. Through practices such as visualization, meditation, and energy work, I help my clients connect with their inner selves and tap into their intuition. My aim is to create a safe space for exploration and transformation, allowing you to discover the deeper meaning in your life and harness the energy that propels you toward your dreams. Together, we will embark on a journey of spiritual awakening and personal empowerment.",
    },
  ];
  return (
    <div className="sm:mt-10 lg:mt-3">
      <h1 className="text-secondary text-7xl text-center px-20 font-semibold sm:text-5xl md:text-6xl sm: -mt-10">
        Who I am
      </h1>
      <p className="text-gray-600 text-md mt-10 mx-32 sm:mx-5">
        Hello Dear Ones.{" "}
      </p>
      <p className="text-gray-600 text-md mt-5 mx-32 sm:mx-5">
        My name is Eldon, I am a beacon of transformation. My journey as a
        successful entrepreneur led me to being a dedicated life coach, guiding
        others toward their highest potential. Through years of experience in
        multiple industries, I have harnessed a deep understanding of personal
        growth, resilience, and spiritual alignment to help you unlock your true
        self. I open your eyes to explore the world, to wonder and admire, to
        understand and grow.
      </p>

      <p className="text-gray-600 text-md mt-5 mx-32 sm:mx-5">
        My intuitive approach, grounded in empathy and wisdom, empowers people
        to embrace their inner light, overcome obstacles, and create lives
        filled with purpose and fulfillment. My mission is to inspire profound,
        lasting change, help others awaken their divine potential and take bold,
        soulful action toward their dreams.
      </p>
      <p className="text-gray-600 text-md mt-5 mx-32 sm:mx-5">
        Now, let's talk about the company name. What is '
        <b className="text-blue-500">Aire Azul</b>?' In Spanish, it means{" "}
        <b className="text-blue-500">blue air</b>. You know when you pull the
        covers over your head and your breath makes the air{" "}
        <b className="text-red-500">hot</b>? That hot air is{" "}
        <b className="text-red-500">red</b>. When you pull the covers off, you
        are now breathing fresh cool air. That air is{" "}
        <b className="text-blue-500">blue</b>. Get it?{" "}
        <b className="text-blue-500">Blue Air</b>? That's how you'll feel when
        you achieve your goals, you feel the fresh cool air when you make a
        breakthrough or achieve a goal.
      </p>
      <div className="h-96 w-full bg-primary mt-12 sm:hidden md:hidden md:mb-4"></div>
      <div className="grid grid-cols-4 sm:grid-cols-1 gap-10 mx-32 sm:mx-0 -mt-80 sm:w-full sm:px-5 md:grid-cols-1 xl:grid-cols-1 lg:grid-cols-1  md:m-5 md:mr-5 md:w-[95%]">
        {items.map((item) => (
          <div className="p-5 bg-white border shadow flex flex-col space-y-5 items-center transform hover:scale-105 duration-300 sm:selection:w-full sm:mr-4 md:mr-7">
            <h1 className="text-2xl">{item.title}</h1>
            <p className="text-gray-600 text-md">{item.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default WhatWeDo;
