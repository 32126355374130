import React, { useState } from "react";
import emailjs from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha"; // Import ReCAPTCHA

function ContactForm() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  const [captchaValue, setCaptchaValue] = useState(null); // State for CAPTCHA

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value); // Store CAPTCHA value
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if all fields are filled
    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.email ||
      !formData.phone ||
      !formData.message
    ) {
      alert("Please fill out all fields before submitting.");
      return; // Stop the function if validation fails
    }

    // Check if CAPTCHA is completed
    if (!captchaValue) {
      alert("Please complete the CAPTCHA.");
      return; // Stop the function if CAPTCHA is not completed
    }

    // EmailJS service
    emailjs
      .send(
        "service_ceqrthn",
        "template_5piqigi",
        formData,
        "YZ7BsZqY-CuJUp0MH"
      )
      .then((result) => {
        console.log(result.text);
        alert("Message sent successfully!");
      })
      .catch((error) => {
        console.log(error.text);
        alert("Failed to send message.");
      });

    // Clear the form after submission
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      message: "",
    });
    setCaptchaValue(null); // Reset CAPTCHA value
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="flex flex-col space-y-10 px-5">
        <h1 className="text-4xl font-semibold text-primary mb-5">
          Send me a message.
        </h1>
        <div className="flex space-x-10 sm:flex-col sm:space-y-10 sm:space-x-0">
          <input
            type="text"
            name="firstName"
            placeholder="First name"
            value={formData.firstName}
            onChange={handleChange}
            className="border py-3 px-5 bg-gray-100 focus:outline-none rounded-lg text-gray-500 w-full"
          />
          <input
            type="text"
            name="lastName"
            placeholder="Last name"
            value={formData.lastName}
            onChange={handleChange}
            className="border py-3 px-5 bg-gray-100 focus:outline-none rounded-lg text-gray-500 w-full"
          />
        </div>
        <div className="flex space-x-10 sm:flex-col sm:space-y-10 sm:space-x-0">
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            className="border py-3 px-5 bg-gray-100 focus:outline-none rounded-lg text-gray-500 w-full"
          />
          <input
            type="text"
            name="phone"
            placeholder="Phone Number"
            value={formData.phone}
            onChange={handleChange}
            className="border py-3 px-5 bg-gray-100 focus:outline-none rounded-lg text-gray-500 w-full"
          />
        </div>
        <div className="flex space-x-10">
          <textarea
            name="message"
            rows={5}
            placeholder="Tell me what you don't like about yourself. What do you want to work on?"
            value={formData.message}
            onChange={handleChange}
            className="border py-3 px-5 bg-gray-100 focus:outline-none rounded-lg text-gray-500 w-full"
          />
        </div>

        <ReCAPTCHA
          sitekey="6Lcu0DIqAAAAAL6hBU3paobmzChmS_1wkpwhnZ-6" // Replace with your site key
          onChange={handleCaptchaChange}
        />

        <div className="flex justify-start">
          <button
            type="submit"
            className="text-white bg-secondary py-3 px-4 rounded max-w-max"
          >
            I'm ready for my transformation
          </button>
        </div>
      </form>
    </div>
  );
}

export default ContactForm;
